import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '@components/layout';
import SEO from '@components/seo';

export default function AboutPage({ data: { about } }) {
  return (
    <Layout page="/about-us">
      <SEO title="About Us" keywords={about.tags} />
      <AboutPageStyles>
        <div className="content">
          <div className="about-us-image" />
          <div
            className="about-us-text"
            dangerouslySetInnerHTML={{ __html: about.data.copy.html }}
          />
        </div>
      </AboutPageStyles>
    </Layout>
  );
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

const AboutPageStyles = styled.div`
  background: ${props => props.theme.white};
  margin: auto;
  max-width: calc(
    ${props => props.theme.maxWidth} - ${props => props.theme.gutterLg} * 2
  );
  padding: 5rem 3rem;
  width: 100%;

  .content {
    margin: auto;
    max-width: 80rem;
  }
`;

export const query = graphql`
  query aboutPage {
    about: prismicAboutPage {
      id
      tags
      uid
      data {
        title {
          text
        }
        copy {
          text
          html
        }
      }
    }
  }
`;
